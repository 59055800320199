import {
  Box,
  Text,
  Flex,
  useDisclosure,
  useToast,
  Button,
} from "@chakra-ui/react";
import CartProductCard from "./card";
import { rem } from "polished";
import { FC, useEffect, useState } from "react";
import { useCartStore } from "store/cart";
import { media } from "@utils/media";
import CartDeleteModal, { CartMobileModal } from "./modals";
import { ShoppingCartIcon } from "icons";
import { motion } from "framer-motion";
import { useLanguageTranslation } from "hooks/useLanguageTranslation";
import { deleteProductFromCart } from "services/api/cart";
import {
  addDataLayerEvent,
  calculateAmount,
  clearDataLayerEcommerceEvent,
  Events,
  formatItemListForDataLayer,
} from "@utils/dataLayer";
import { useNavigate } from "react-router-dom";
import { hexToRGB } from "@utils/colors";
import { currencyFormatter } from "@utils/formatPrice";
import { useAuthStore } from "store/authentication";
import { isInsuranceCalculatorRoute } from "@utils/getFlowPaths";
import { useColors } from "hooks/useColors";
import Editor from "features/editor";
import { CART_COLOR_FIELDS } from "src/constants";
import { usePartnerStore } from "store/partner";

const Cart: FC<CartProps> = ({ isFunnelStarted = false }) => {
  const navigate = useNavigate();
  const insuranceCalculatorRoute = isInsuranceCalculatorRoute();
  const { name } = usePartnerStore();
  const { admin } = useAuthStore();
  const { t } = useLanguageTranslation();
  const { cartItemList, getCartItemList, setAOVCartItem } = useCartStore();
  const deleteModal = useDisclosure();
  const mobileCartModal = useDisclosure();
  const toast = useToast();
  const { getColor } = useColors();

  const [selectedCartItemId, setSelectedCartItemId] = useState("");

  const ShoppingCart = ShoppingCartIcon({
    color: getColor("cart-empty-icon", "text"),
  });
  const ShoppingCartWhite = ShoppingCartIcon({ color: "cart-icon-mobile" });

  useEffect(() => {
    if (cartItemList.length === 0) {
      mobileCartModal.onClose();
    }
  });

  const onProductDelete = (productId: string) => {
    setSelectedCartItemId(productId);
    deleteModal.onOpen();
  };

  const onHandleProductDeleteConfirm = async () => {
    try {
      await deleteProductFromCart(selectedCartItemId);
      addDataLayerEvent(Events.remove_from_cart, "", "", {
        ecommerce: {
          currency: "EUR",
          value: calculateAmount(
            cartItemList.filter((item) => item.id === selectedCartItemId)
          ),
          items: formatItemListForDataLayer(
            cartItemList.filter((item) => item.id === selectedCartItemId)
          ),
        },
      });
      if (
        cartItemList.filter(
          (item) =>
            item.id === selectedCartItemId &&
            item.insurance_product.type === "aov"
        )?.length === 1
      ) {
        setAOVCartItem([]);
      }
      await getCartItemList();
    } catch (error) {
      toast({
        title: String(error),
        variant: "solid",
        isClosable: true,
        status: "warning",
      });
    } finally {
      deleteModal.onClose();
    }
  };

  const onContinue = () => {
    addDataLayerEvent(
      Events.button_click,
      "bereken premie",
      insuranceCalculatorRoute ? "/insurance-calculator/checkout" : "/checkout"
    );
    clearDataLayerEcommerceEvent();
    addDataLayerEvent(Events.begin_checkout, "", "", {
      ecommerce: {
        value: calculateAmount(cartItemList),
        currency: "EUR",
        items: formatItemListForDataLayer(cartItemList),
      },
    });
    navigate(
      insuranceCalculatorRoute ? "/insurance-calculator/checkout" : "/checkout"
    );
  };

  return (
    <>
      <CartDeleteModal
        productName={
          cartItemList.find(({ id }) => id === selectedCartItemId)
            ?.insurance_product.name ?? ""
        }
        isOpen={deleteModal.isOpen}
        onClose={deleteModal.onClose}
        onProductDeleteConfirm={onHandleProductDeleteConfirm}
      />
      <CartMobileModal
        isOpen={mobileCartModal.isOpen}
        onClose={() => mobileCartModal.onClose()}
        cartItemList={cartItemList}
        isFunnelStarted={isFunnelStarted}
        onContinue={onContinue}
        onCartItemDelete={(e) => {
          onProductDelete(e);
        }}
      />
      <Flex
        sx={{
          display: "none",
          [media.desktop]: {
            display: "flex",
            position: "fixed",
            bottom: rem(20),
            right: rem(20),
            flexDirection: "column",
            zIndex: 8,
          },
        }}
      >
        <Flex position="relative">
          {!isFunnelStarted && cartItemList?.length !== 0 && (
            <Flex position="fixed" left={rem(10)} bottom={rem(20)}>
              <Button
                mt={rem(16)}
                variant="primary"
                isDisabled={cartItemList?.length === 0}
                rounded="full"
                sx={{
                  height: rem(56),
                }}
                px={rem(100)}
                onClick={onContinue}
                fontSize={rem(18)}
                lineHeight={rem(24)}
                fontWeight={500}
                data-testid="checkout-btn"
              >
                <span>{t("common.continue")}</span>
              </Button>
            </Flex>
          )}
          {cartItemList?.length > 0 && (
            <Text
              position="absolute"
              top={rem(-10)}
              right={rem(-5)}
              zIndex={1}
              sx={{
                borderRadius: "50%",
                background: getColor(
                  "cart-mobile-counter-background",
                  "background-card"
                ),
                color: getColor("cart-mobile-counter-text"),
                px: rem(8),
              }}
            >
              {cartItemList?.length}
            </Text>
          )}
          {cartItemList?.length !== 0 && (
            <Button
              variant="primary"
              isDisabled={cartItemList?.length === 0}
              rounded="full"
              sx={{
                height: rem(50),
              }}
              onClick={() => {
                mobileCartModal.onOpen();
              }}
              fontSize={rem(18)}
              lineHeight={rem(24)}
              fontWeight={500}
              data-testid="checkout-btn"
            >
              <ShoppingCartWhite />
              {isFunnelStarted &&
                Boolean(
                  Number(
                    String(calculateAmount(cartItemList).toFixed(2) ?? "0.00")
                  )
                ) && (
                  <Flex ml={rem(8)} as="span">
                    {Boolean(cartItemList?.length) &&
                      currencyFormatter(
                        String(
                          calculateAmount(cartItemList).toFixed(2) ?? "0.00"
                        ),
                        cartItemList[0]?.insurance_product.price_currency ??
                          "EUR"
                      )}
                  </Flex>
                )}
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex
        justifyContent="space-between"
        height="100%"
        zIndex={1}
        bg="white"
        sx={{
          width: rem(320),
          flexDirection: "column",
          position: "relative",
          borderLeft: `${rem(1)} solid ${hexToRGB(
            getColor("cart-border", "text") ?? "",
            "0.2"
          )}`,
          borderTop: `${rem(1)} solid ${hexToRGB(
            getColor("cart-border", "text") ?? "",
            "0.2"
          )}`,
          display: "flex",
          [media.desktop]: {
            display: "none",
          },
        }}
      >
        <Editor
          isAdmin={admin && admin.name === name}
          colorsFields={CART_COLOR_FIELDS}
          component={
            <Box
              sx={{
                display: "block",
                height: "100%",
                width: "100%",
                overflowY: "auto",
                mb: rem(12),
                pt: rem(24),
              }}
            >
              <Flex
                flexDirection="column"
                sx={{
                  padding: rem(24),
                }}
              >
                <Text
                  fontSize={rem(20)}
                  lineHeight={rem(24)}
                  fontWeight="bold"
                  color={getColor("cart-title")}
                >
                  {t("cart.selectedInsurances")}
                </Text>
                {cartItemList && cartItemList?.length > 0 ? (
                  <Box maxHeight="100%">
                    {cartItemList?.map(
                      ({ id, insurance_product, list_items }) => (
                        <motion.div
                          key={id}
                          animate={{ x: [-40, 20, 0] }}
                          transition={{ type: "spring", stiffness: 100 }}
                        >
                          <CartProductCard
                            key={id}
                            name={insurance_product.name ?? ""}
                            amount={Number(insurance_product.price) || 0}
                            onDelete={onProductDelete}
                            listItems={list_items}
                            currency={insurance_product.price_currency}
                            isFunnelStarted={isFunnelStarted}
                            cartItemId={id}
                            netPrice={insurance_product.net_price}
                          />
                        </motion.div>
                      )
                    )}
                  </Box>
                ) : (
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    bg={getColor("cart-empty-background") ?? "#FAFAFA"}
                    rounded="md"
                    py={rem(8)}
                    px={rem(18)}
                    sx={{
                      marginTop: rem(54),
                      [media.desktop]: {
                        marginTop: rem(0),
                        justifyContent: "center",
                        paddingTop: rem(48),
                      },
                    }}
                  >
                    <motion.div
                      animate={{ x: [-40, 20, 0] }}
                      transition={{ type: "spring", stiffness: 100 }}
                    >
                      <ShoppingCart width={rem(27)} height={rem(72)} />
                    </motion.div>
                    <Text
                      ml={4}
                      fontSize={rem(14)}
                      color={getColor("cart-empty-description")}
                    >
                      {t("cart.AddProductsToCart")}
                    </Text>
                  </Flex>
                )}
              </Flex>
            </Box>
          }
        />
        <Flex
          flexDirection="column"
          borderTop={`${rem(1)} solid ${hexToRGB(
            getColor("cart-border", "text") ?? "",
            "0.2"
          )}`}
          py={rem(16)}
          px={rem(24)}
          alignItems="center"
        >
          <Flex w="100%" justifyContent="space-between">
            <Flex alignItems="center">
              <motion.div
                animate={{ y: [-40, 20, 0] }}
                transition={{ type: "spring", stiffness: 100 }}
              >
                <Text
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: getColor(
                      "cart-counter-background",
                      "accent-positive-3"
                    ),
                    color: getColor("cart-counter-text"),
                    paddingLeft: rem(12),
                    paddingRight: rem(12),
                    fontWeight: "bold",
                  }}
                  fontSize={rem(14)}
                  lineHeight={rem(32)}
                >
                  {cartItemList.length}
                </Text>
              </motion.div>
              <Text ml={rem(8)} fontWeight="bold" lineHeight={rem(24)}>
                {t("cart.total")}
              </Text>
            </Flex>
            <Flex alignItems="center" color={getColor("cart-amount")}>
              {Boolean(
                Number(
                  String(calculateAmount(cartItemList).toFixed(2) ?? "0.00")
                )
              ) && (
                <Text textAlign="center">
                  {Boolean(cartItemList.length) &&
                    currencyFormatter(
                      String(
                        calculateAmount(cartItemList).toFixed(2) ?? "0.00"
                      ),
                      cartItemList[0]?.insurance_product.price_currency ?? "EUR"
                    )}{" "}
                  {t("common.perMonthShort")}
                </Text>
              )}
            </Flex>
          </Flex>
          {!isFunnelStarted && (
            <Button
              mt={rem(16)}
              variant="primary"
              isDisabled={cartItemList.length === 0}
              rounded="full"
              sx={{
                height: rem(56),
              }}
              w={rem(270)}
              onClick={onContinue}
              fontSize={rem(18)}
              lineHeight={rem(24)}
              fontWeight={500}
              data-testid="checkout-btn"
            >
              <span>{t("common.continue")}</span>
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};

interface CartProps {
  isFunnelStarted?: boolean;
}

export default Cart;
