import { ErrorType } from "src/types";
import * as Sentry from "@sentry/react";
import { CustomErrorCodes } from "src/constants/server-error-codes";

export const formatToastErrors = (
  error: any,
  customError?: { type: ErrorType; message: string }
) => {
  let errorType: ErrorType = "other";
  if (error.response) {
    if (
      error.response.status === 401 &&
      customError &&
      customError.type === "login"
    ) {
      errorType = "login";
    } else if (error.response?.data?.email) {
      errorType = "email-exists";
    } else if (error.response?.data?.postal_code) {
      errorType = "postal_code-invalid";
    }
  } else {
    return constructErrorMessage(99999, errorType);
  }

  return constructErrorMessage(error.response.status, errorType);
};

const uuidv4 = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  );
};

const constructErrorMessage = (status: number, type?: ErrorType) => {
  const aliciaErrorId = uuidv4();
  if (type === "login") {
    Sentry.captureException(
      new Error(
        `${CustomErrorCodes.loginFailed.message} Error code: ${aliciaErrorId}`
      )
    );
    return `${CustomErrorCodes.loginFailed.message} Error code: ${aliciaErrorId}`;
  } else if (type === "email-exists") {
    Sentry.captureException(
      new Error(
        `User with this email already exists. Error code: ${aliciaErrorId}`
      )
    );
    return `${CustomErrorCodes.emailExist.message} Error code: ${aliciaErrorId}`;
  } else if (type === "postal_code-invalid") {
    return `${CustomErrorCodes.invalidPostalCode.message} Error code: ${aliciaErrorId}`;
  } else {
    const val = Object.keys(CustomErrorCodes).includes(status.toString());
    if (val) {
      Sentry.captureException(
        new Error(
          `${
            CustomErrorCodes[status as unknown as keyof typeof CustomErrorCodes]
              .message
          } Error code: ${aliciaErrorId}`
        )
      );
      return `${
        CustomErrorCodes[status as unknown as keyof typeof CustomErrorCodes]
          .message
      } Error code: ${aliciaErrorId}`;
    } else {
      Sentry.captureException(
        new Error(
          `${CustomErrorCodes.unknown.message} Error code: ${aliciaErrorId}`
        )
      );
      return `${CustomErrorCodes.unknown.message} Error code: ${aliciaErrorId}`;
    }
  }
};
