import * as Yup from "yup";
import IBAN from "iban";
import { TFunction } from "i18next";
import {
  EMAIL_REGEX,
  PHONE_MAX_LENGTH,
  PHONE_MIN_LENGTH,
  PHONE_REGEX,
} from "src/constant";
import moment from "moment";
import { PickerInputData } from "features/editor/types";

// company search form validations
export const CompanySearchValidation = (t: TFunction) =>
  Yup.object().shape({
    company_name: Yup.string().required(t("validations.required") as string),
    chamber_of_commerce_number: Yup.number().required(
      t("validations.required") as string
    ),
    street: Yup.string().required(t("validations.required") as string),
    house_number: Yup.string().required(t("validations.required") as string),
    house_number_addition: Yup.string().optional(),
    postal_code: Yup.string()
      .matches(/^\S*$/, "Spaties zijn niet toegestaan")
      .required(t("validations.required") as string),
    city: Yup.string().required(t("validations.required") as string),
    country: Yup.string().required(t("validations.required") as string),
  });

// personal address form validations
export const PersonalAddressValidation = (t: TFunction) =>
  Yup.object().shape({
    street: Yup.string().required(t("validations.required") as string),
    house_number: Yup.string().required(t("validations.required") as string),
    house_number_addition: Yup.string().optional(),
    postal_code: Yup.string()
      .matches(/^\S*$/, "Spaties zijn niet toegestaan")
      .required(t("validations.required") as string),
    city: Yup.string().required(t("validations.required") as string),
    country: Yup.string().required(t("validations.required") as string),
  });

// profession create validations
export const ProfessionCreateValidation = (t: TFunction) =>
  Yup.object().shape({
    profession: Yup.string().required(t("validations.required") as string),
    firstName: Yup.string().required(t("validations.required") as string),
    email: Yup.string()
      .matches(EMAIL_REGEX, t("validations.email") as string)
      .required(t("validations.required") as string),
  });

// user personal details create validations
export const PersonalDetailsValidation = (t: TFunction) =>
  Yup.object().shape({
    firstName: Yup.string().required(t("validations.required") as string),
    lastName: Yup.string().required(t("validations.required") as string),
    birthDate: Yup.date()
      .max(
        moment(new Date()).startOf("y").subtract(18, "years").toDate(),
        t("validations.birthDateMinDate") as string
      )
      .min(
        moment(new Date()).startOf("y").subtract(100, "years").toDate(),
        t("validations.birthDate100YearMaxDate") as string
      )
      .required(t("validations.required") as string)
      .transform(function (value, originalValue) {
        if (this.isType(originalValue)) {
          return originalValue;
        }
        const result = moment(originalValue, "YYYY-MM-DD", true);
        if (result.isValid()) {
          return value;
        } else {
          return "";
        }
      })
      .typeError(t("validations.dateValidMessage") as string),
    phoneNumber: Yup.string()
      .required(t("validations.required") as string)
      .matches(PHONE_REGEX, t("validations.phoneNumber") as string)
      .min(PHONE_MIN_LENGTH, t("validations.phoneNumberMinLength") as string)
      .max(PHONE_MAX_LENGTH, t("validations.phoneNumberMaxLength") as string),
    email: Yup.string()
      .matches(EMAIL_REGEX, t("validations.email") as string)
      .required(t("validations.required") as string),
  });

// payment validations
export const PaymentValidation = (
  t: TFunction,
  validatePolicyStartDate: boolean,
  isInvoiceStrategyAvailable: boolean
) =>
  Yup.object().shape({
    start_date: Yup.date()
      .max(
        moment(new Date()).startOf("d").add(1, "M"),
        t("validations.maxDateReached") as string
      )
      .min(
        validatePolicyStartDate
          ? moment(new Date()).startOf("d").add(1, "d")
          : moment(new Date()).startOf("d"),
        t("validations.minDateReached") as string
      )
      .required(t("validations.required") as string)
      .transform(function (value, originalValue) {
        if (this.isType(originalValue)) {
          return originalValue;
        }
        const result = moment(originalValue, "YYYY-MM-DD", true);
        if (result.isValid()) {
          return value;
        } else {
          return "";
        }
      })
      .typeError(t("validations.dateValidMessage") as string),
    iban_number: isInvoiceStrategyAvailable
      ? Yup.string()
          .required(t("validations.required") as string)
          .test("check-iban-number", t("validations.iban") as string, (value) =>
            IBAN.isValid(value)
          )
      : Yup.string().optional(),
    consent: Yup.boolean().optional(),
  });

// Questions create validations
export const QuestionDateValidation = (t: TFunction) =>
  Yup.object().shape({
    birthDate: Yup.date()
      .max(
        moment(new Date()).startOf("y").subtract(18, "years").toDate(),
        t("validations.birthDateMinDate") as string
      )
      .min(
        moment(new Date()).startOf("y").subtract(58, "years").toDate(),
        t("validations.birthDateMaxDate") as string
      )
      .required(t("validations.required") as string)
      .transform(function (value, originalValue) {
        if (this.isType(originalValue)) {
          return originalValue;
        }
        const result = moment(originalValue, "YYYY-MM-DD", true);
        if (result.isValid()) {
          return value;
        } else {
          return "";
        }
      })
      .typeError(t("validations.dateValidMessage") as string),
  });

export const QuestionIncomeValidation = (t: TFunction) =>
  Yup.object().shape({
    income: Yup.number()
      .typeError("Must be a number")
      .required(t("validations.required") as string)
      .max(125000, t("validations.maxIncome") as string),
  });

export const TwoPremiumQuestionValidation = (t: TFunction) =>
  Yup.object().shape({
    birthDate: Yup.date()
      .max(
        moment(new Date()).startOf("y").subtract(18, "years").toDate(),
        t("validations.birthDateMinDate") as string
      )
      .min(
        moment(new Date()).startOf("y").subtract(58, "years").toDate(),
        t("validations.birthDateMaxDate") as string
      )
      .required(t("validations.required") as string)
      .transform(function (value, originalValue) {
        if (this.isType(originalValue)) {
          return originalValue;
        }
        const result = moment(originalValue, "YYYY-MM-DD", true);
        if (result.isValid()) {
          return value;
        } else {
          return "";
        }
      })
      .typeError(t("validations.dateValidMessage") as string),

    income: Yup.number()
      .typeError("Must be a number")
      .required(t("validations.required") as string)
      .max(125000, t("validations.maxIncome") as string),
  });

export const QuestionPriceValidation = (t: TFunction) =>
  Yup.object().shape({
    price: Yup.number().required(t("validations.required") as string),
  });
export const QuestionBmiValidation = (t: TFunction) =>
  Yup.object().shape({
    height: Yup.number()
      .required(t("validations.required") as string)
      .min(100, t("validations.minHeight") as string)
      .max(250, t("validations.maxHeight") as string),

    weight: Yup.number()
      .required(t("validations.required") as string)
      .min(50, t("validations.minWeight") as string)
      .max(200, t("validations.maxWeight") as string),
  });

export const CancelPolicyReasonValidation = (t: TFunction) =>
  Yup.object().shape({
    reasonDescription: Yup.string().required(
      t("validations.required") as string
    ),
  });

export const CancelPolicySuggestionValidation = (t: TFunction) =>
  Yup.object().shape({
    suggestion: Yup.string().required(t("validations.required") as string),
  });

export const QuestionSuggestionValidation = (t: TFunction) =>
  Yup.object().shape({
    suggestion: Yup.string().required(t("validations.required") as string),
  });

export const DeclarationRejectValidation = (t: TFunction) =>
  Yup.object().shape({
    reason: Yup.string().required(t("validations.required") as string),
  });

export const SigninValidation = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .matches(EMAIL_REGEX, t("validations.email") as string)
      .required(t("validations.required") as string),
  });

export const ContactFormValidation = (t: TFunction) =>
  Yup.object().shape({
    firstName: Yup.string().required(t("validations.required") as string),
    lastName: Yup.string().required(t("validations.required") as string),
    phoneNumber: Yup.string()
      .required(t("validations.required") as string)
      .matches(PHONE_REGEX, t("validations.phoneNumber") as string)
      .min(PHONE_MIN_LENGTH, t("validations.phoneNumberMinLength") as string)
      .max(PHONE_MAX_LENGTH, t("validations.phoneNumberMaxLength") as string),
    email: Yup.string()
      .matches(EMAIL_REGEX, t("validations.email") as string)
      .required(t("validations.required") as string),
  });

export const QuestionTextInputValidation = (t: TFunction) =>
  Yup.object().shape({
    input: Yup.string().required(t("validations.required") as string),
  });

export const ColorInputValidation = (t: TFunction, colors: PickerInputData[]) =>
  Yup.object().shape(
    colors.reduce(
      (a, v) => ({
        ...a,
        [v.id as string]: Yup.object().shape({
          value: Yup.string().required(t("validations.required") as string),
          key: Yup.string().required(t("validations.required") as string),
        }),
      }),
      {}
    )
  );

// User Profile update
export const UserDetailsValidation = (t: TFunction) =>
  Yup.object().shape({
    firstName: Yup.string().required(t("validations.required") as string),
    lastName: Yup.string().required(t("validations.required") as string),
    email: Yup.string()
      .matches(EMAIL_REGEX, t("validations.email") as string)
      .required(t("validations.required") as string),
    phoneNumber: Yup.string()
      .required(t("validations.required") as string)
      .matches(PHONE_REGEX, t("validations.phoneNumber") as string)
      .min(PHONE_MIN_LENGTH, t("validations.phoneNumberMinLength") as string)
      .max(PHONE_MAX_LENGTH, t("validations.phoneNumberMaxLength") as string),
  });

export const UserAddressValidation = (t: TFunction) =>
  Yup.object().shape({
    street: Yup.string().required(t("validations.required") as string),
    houseNumber: Yup.string().required(t("validations.required") as string),
    addition: Yup.string().optional(),
    city: Yup.string().required(t("validations.required") as string),
    postalCode: Yup.string()
      .matches(/^\S*$/, "Spaties zijn niet toegestaan")
      .required(t("validations.required") as string),
    country: Yup.string().required(t("validations.required") as string),
  });

export const UserIbanValidation = (t: TFunction) =>
  Yup.object().shape({
    iban_number: Yup.string()
      .required(t("validations.required") as string)
      .test("check-iban-number", t("validations.iban") as string, (value) =>
        IBAN.isValid(value)
      ),
  });

const validateIMEI = (imei: string) => {
  if (!/^\d{15}$/.test(imei)) {
    return false; // IMEI must be exactly 15 digits
  }

  let sum = 0;
  for (let i = 0; i < 15; i++) {
    let digit = parseInt(imei.charAt(i), 10);
    if (i % 2 === 1) {
      // Double every second digit
      digit *= 2;
      if (digit > 9) {
        // If doubling results in a number greater than 9, subtract 9
        digit -= 9;
      }
    }
    sum += digit;
  }

  return sum % 10 === 0; // Valid IMEI if sum is divisible by 10
};

export const DeviceIMEIValidation = (t: TFunction) =>
  Yup.object().shape({
    imeiNumber: Yup.string()
      .required(t("validations.required") as string)
      .test("check-imei-number", t("validations.imei") as string, (value) =>
        validateIMEI(value)
      ),
  });

export const BAVCoverageValidation = (t: TFunction) =>
  Yup.object().shape({
    perClaim: Yup.string().required(t("validations.required") as string),
    perYear: Yup.string().required(t("validations.required") as string),
  });
